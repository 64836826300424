<template>
  <div>
    <vs-table :data="proposalData" height="100%">
      <template slot="thead">
        <vs-th> Invoice Info </vs-th>
        <vs-th> Paid </vs-th>
        <vs-th> Paid Now </vs-th>
      </template>
      <template slot-scope="{ data }">
        <vs-tr :key="indextr" v-for="(proposal, indextr) in data">
          <vs-td>
            <p>
              <span class="font-bold">Code</span> :
              {{ proposalData[indextr].Code }}
            </p>
            <p>
              <span class="font-bold">Supplier Code</span> :
              {{ proposalData[indextr].SupplierCode }}
            </p>
            <p>
              <span class="font-bold">Supplier Name</span> :
              {{ proposalData[indextr].SupplierName }}
            </p>
            <p>
              <span class="font-bold">Delivery Number</span> :
              {{ proposalData[indextr].DeliveryNumber }}
            </p>
            <p>
              <span class="font-bold">Purchase Invoice Code</span>:
              {{ proposalData[indextr].PurchaseInvoiceCode }}
            </p>
            <p>
              <span class="font-bold">Purchase Invoice Code Internal</span> :
              {{ proposalData[indextr].PurchaseInvoiceCodeInternal }}
            </p>
          </vs-td>
          <vs-td>
            {{ priceFormat(proposalData[indextr].PaidValue) }} ({{
              currencyCode
            }})
          </vs-td>
          <vs-td>
            <input-paidnow
              :paidnowValue="proposalData[indextr].PaidValue"
              :proposalId="proposalData[indextr].ID"
            />
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
import moment from "moment";
import InputPaidNow from "./input-paid-now.vue";
export default {
  props: {
    proposalData: Array,
    isOpen: Boolean,
    currencyCode: String,
  },
  components: {
    "input-paidnow": InputPaidNow,
  },
  methods: {
    dateFormat(date) {
      return moment(date).format("YYYY-MM-DD");
    },
  },
  watch: {
    isOpen(val) {
      if (val) {
        const temp = this.proposalData.map((item) => ({
          id: item.ID,
          value: item.PaidValue,
        }));
        this.$store.commit("clearingDN/setProposalPaidNows", temp);
      } else {
        this.$store.commit("clearingDN/setProposalPaidNows", []);
      }
    },
  },
};
</script>
