<template>
  <vs-input v-model="displayValue" type=""/>
</template>

<script>
export default {
  computed: {
    displayValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.value = val;
        const temp = [...this.$store.state.clearingDN.proposalPaidNows];
        const index = temp.findIndex((item) => item.id === this.proposalId);
        if (index > -1) {
          const tempVal = val.replace(/,/g, "");
          const newValue = parseFloat(tempVal) || 0;
          this.$store.commit("clearingDN/updateProposalPaidNow", {
            index,
            newValue,
          });
        } else {
          return;
        }
      },
    },
  },
  data() {
    return { value: this.paidnowValue.toString() };
  },
  props: {
    paidnowValue: Number,
    proposalId: Number,
  },
};
</script>
